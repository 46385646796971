import io from 'socket.io-client';

const SOCKET_URL = 'https://mindleague.com:2083'; // Replace with your server URL
const socket = io(SOCKET_URL, {
  transports: ['websocket'], // Use WebSocket first
  jsonp: false,
});
// Log the socket ID when connected
socket.on('connect', () => {
  console.log(`Socket connected with ID: ${socket.id}`);
});

export default socket;
