import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import socket from "../../../../socket-config";

const LiveCam = ({ userData, matchID, capture, closeCam }) => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  console.log(closeCam);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const iceCandidatesQueue = useRef([]);

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerConnectionRef = useRef(null);

  const currentUser = userData?._id;
  const otherUser =
    currentUser === capture?.player1 ? capture?.player2 : capture?.player1;

  const createPeerConnection = () => {
    const config = {
      iceServers: [
        { urls: "stun:stun.l.google.com:19302" },
        { urls: "stun:stun1.l.google.com:19302" },
      ],
    };

    const pc = new RTCPeerConnection(config);

    pc.ontrack = (event) => {
      if (event.streams && event.streams[0]) {
        setRemoteStream(event.streams[0]);
      }
    };

    pc.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit("ice-candidate", {
          from: currentUser,
          to: otherUser,
          candidate: event.candidate,
          matchId: capture?._id,
        });
      }
    };

    pc.oniceconnectionstatechange = () => {
      setConnectionStatus(pc.iceConnectionState);
    };

    peerConnectionRef.current = pc;
    return pc;
  };

  const stopLiveStream = () => {
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
    socket.emit("end-call", {
      from: currentUser,
      to: otherUser,
      matchId: capture?._id,
    });
    setRemoteStream(null);
  };

  useEffect(() => {
    if (closeCam) {
      console.log("Option Coming");
      stopLiveStream();
    }
  }, [closeCam]);

  useEffect(() => {
    const setupMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        setLocalStream(stream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }

        const pc = peerConnectionRef.current || createPeerConnection();
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      } catch (err) {
        console.error("Media access error:", err);
      }
    };

    setupMedia();

    return () => {
      stopLiveStream();
    };
  }, []);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const startCall = async () => {
    try {
      const pc = peerConnectionRef.current || createPeerConnection();
      if (!localStream) return;

      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      socket.emit("offer", {
        from: currentUser,
        to: otherUser,
        offer: pc.localDescription,
        matchId: capture?._id,
      });
    } catch (err) {
      console.error("Error starting call:", err);
    }
  };

  return (
    <>
      <div className="col-6">
        <div className="videocall_box">
          <video
            ref={localVideoRef}
            autoPlay
            playsInline
            muted
            className="w-full h-48 bg-black rounded"
          />
          <div className="hover_video">
            <h3>
              {userData?.firstname} {userData?.lastname}
            </h3>
            <p>Status: {connectionStatus}</p>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="videocall_box">
          <video
            ref={remoteVideoRef}
            autoPlay
            playsInline
            className="w-full h-48 bg-black rounded"
          />
          <div className="hover_video">
            <h3>Remote User</h3>
            <div className="videocall_btnsss">
              <button onClick={stopLiveStream}>
                <img
                  src="../assets/img/comman/phone-call.png"
                  alt="Start Call"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveCam;
